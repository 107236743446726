<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div v-if="$slots.image" class="card-image">
      <slot name="image" />
    </div>
    <div
      v-if="$slots.header || title"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <p v-if="subTitle" class="card-category">
          {{ subTitle }}
        </p>
      </slot>
    </div>
    <div v-if="$slots.default" class="card-body" :class="bodyClasses">
      <slot />
    </div>
    <div v-if="$slots['image-bottom']" class="card-image">
      <slot name="image-bottom" />
    </div>
    <slot name="raw-content" />
    <div v-if="$slots.footer" class="card-footer" :class="footerClasses">
      <hr v-if="showFooterLine">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      description: 'Card title',
      default: ''
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
      default: ''
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)',
      default: ''
    },
    showFooterLine: {
      type: Boolean,
      default: false
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes',
      default: ''
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes',
      default: ''
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes',
      default: ''
    }
  }
}
</script>
<style></style>
