<template>
  <router-view />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  metaInfo: {
    title: 'FIAIP 1 TOUCH'
  },
  data(){
    return{
      location:{
          lat: null,
          lon: null
      }
    }
  },
  methods: {
    ...mapActions({
      setLocation: 'location/setLocation',
    }),

    setPosition(lat, lon){
      this.location.lat = lat
      this.location.lon = lon
      this.setLocation(this.location)
    }
  },

  beforeMount() {
    window['App'] = {
      components: this,
      location: (lat, lon) => this.setPosition(lat, lon)
    }
  },

  created() {
    var standalone = window.navigator.standalone
    var userAgent = window.navigator.userAgent.toLowerCase()
    var safari = /safari/.test(userAgent)
    var ios = /iphone|ipod|ipad|macintosh/.test(userAgent)

    if (ios) {
      if ((!standalone && !safari) || (/(iPhone|iPod|iPad|Macintosh).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent))) {
        // iOS webview
        window.location = 'https://position.request/'
      }
    } else {
      if (userAgent.includes('wv')) {
        // Android webview
        window.location = 'https://position.request/'
      }
    }
  }
}
</script>
