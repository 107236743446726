const ListNews = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/News/List.vue')

export default [
  {
    path: '/news',
    name: 'News',
    components: { default: ListNews }
  }

]
