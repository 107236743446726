import { render, staticRenderFns } from "./RowsPerPage.vue?vue&type=template&id=470dfcaa&scoped=true&"
import script from "./RowsPerPage.vue?vue&type=script&lang=js&"
export * from "./RowsPerPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470dfcaa",
  null
  
)

export default component.exports