const ConcurrentLoginsRoutes = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/ConcurrentLogins/List.vue')
const ConcurrentLoginsDetails = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/ConcurrentLogins/Details.vue')

export default [
    {
        path: '/log-utenti',
        name: 'Log Accessi Simultanei',
        meta: { needsAdmin: false },
        components: { default: ConcurrentLoginsRoutes }
    },
    {
        path: 'log-utenti/:user_id/dettagli',
        name: 'Log Accessi Simultanei - Dettagli',
        meta: { needsAdmin: false },
        components: { default: ConcurrentLoginsDetails }
    },
]
