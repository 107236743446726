import Crud from '../utils/crud'
import axios from "axios";
const crud = new Crud('/users')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,

  async sendFirstAccessMail (context, user_id) {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    context.commit('SET_CANCEL_TOKEN', source)
    return await new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${context.state.endpoint}/${user_id}/send-first-access-mail`,
        headers: { 'Content-Type': 'application/json' },
        cancelToken: source.token,
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
