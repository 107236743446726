var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar, 'no-scroll': _vm.lockScreen }},[(_vm.lockScreen)?_c('LockScreen'):_vm._e(),_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('div',[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.calculate'),
            iconPath: '/icons/cambio-locazione.svg',
            path: '/calcoli'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.attachments'),
            iconPath: '/icons/allegati.svg',
            path: '/allegati'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.errors'),
            iconPath: '/icons/segnalazione-errori.svg',
            path: '/errori'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.news'),
            iconPath: '/icons/generazione-pdf.svg',
            path: '/news'
          }}})],1),_c('div',{staticClass:"logo"}),(_vm.$isAdmin())?_c('div',[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.users'),
            iconPath: '/icons/utenti.svg',
            path: '/utenti'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.tables'),
            iconPath: '/icons/tabelle.svg',
            path: '/tabelle'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.manage-errors'),
            iconPath: '/icons/segnalazione-errori.svg',
            path: '/gestione-errori'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.manage-attachments'),
            iconPath: '/icons/allegati.svg',
            path: '/gestione-allegati'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.users-log'),
            iconPath: '/icons/utenti.svg',
            path: '/log-utenti'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.pdf-generation-log'),
            iconPath: '/icons/generazione-pdf.svg',
            path: '/calcoli/log-generazione-pdf'
          }}})],1):_vm._e(),_c('div',{staticStyle:{"text-align":"right","padding-right":"16px","font-size":"9px"}},[_vm._v(_vm._s(_vm.detectBrowserOrApp()))])])],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }