import Crud from '../utils/crud'
import axios from "axios";
import qs from "qs";
const crud = new Crud('/concurrent-logins')

const state = () => ({
    ...crud.state
})
const getters = {
    ...crud.getters
}
const actions = {
    ...crud.actions,

    async fetchDetails (context, user_id) {
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        context.commit('SET_CANCEL_TOKEN', source)
        return await new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${context.state.endpoint}/${user_id}/details`,
                headers: { 'Content-Type': 'application/json' },
                cancelToken: source.token,
            }).then((response) => {
                context.commit('SET_COLLECTION_PAYLOAD', response)
                resolve(response)
            }, (error) => {
                reject(error)
            })
        })
    },
}
const mutations = {
    ...crud.mutations
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
