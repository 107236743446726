const state = () => ({
    location: {
        lat: null,
        lon: null
    }
})
const getters = {
    getLocation(state){
        return state.location
    }
}
const actions = {
    setLocation (context, location) {
        context.commit('SET_LOCATION', location)
    },
}
const mutations = {
    SET_LOCATION (state, location) {
        state.location = location
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
